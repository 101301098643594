import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
  contentWrap: FelaCSS;
  logoWrap: FelaCSS;
  dropdown: FelaCSS;
  contentHeader: FelaCSS;
  contentText: FelaCSS;
  contentBottom: FelaCSS;
  careersBtn: FelaCSS;
  hero: FelaCSS;
  heroContent: FelaCSS;
  headline: FelaCSS;
}

const styles = (): IStyles => ({
  wrapper: {
    display: 'grid',
    minHeight: '100vh',
    gridTemplateRows: 'min-content min-content auto',

    supportsSafeAreaInset: {
      paddingTop: 'env(safe-area-inset-top)',
      paddingBottom: 'env(safe-area-inset-bottom)',
    },

    minWidthSmMin: {
      gridTemplateColumns: 'min-content auto',
      gridTemplateRows: 'min-content auto',
    },
  },

  contentWrap: {
    gridRow: 3,
    width: '100%',
    padding: 5,

    minWidthSmMin: {
      gridColumn: 1,
      gridRow: 2,
      width: 600,
      maxWidth: '50vw',
      padding: 10,
    },

    minWidthMdMin: {
      paddingVertical: 16,
      paddingHorizontal: 24,
    },
  },

  logoWrap: {
    gridRow: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingVertical: 4,
    paddingHorizontal: 5,

    minWidthSmMin: {
      gridRow: 1,
      gridColumn: 1,
      padding: 10,
    },

    minWidthMdMin: {
      paddingVertical: 10,
      paddingHorizontal: 24,
    },
  },

  dropdown: {
    flex: 1,
    fontDefaultSemiBold: 18,
    marginTop: 10,
  },

  contentBottom: {
    marginTop: '20px',
  },

  contentHeader: {
    fontDefaultSemiBold: 18,
    lineHeight: 1.3,
  },

  contentText: {
    fontDefault: 16,
    lineHeight: 1.6,
    color: colors.space60,
  },

  careersBtn: {
    marginTop: 4,
    paddingTop: 4,
    borderTop: `1px solid ${colors.space40}`,
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: colors.space60,

    hover: {
      color: colors.indigoCrush,
    },
  },

  hero: {
    gridRow: 2,
    backgroundColor: '',
    backgroundImage: `url("https://bridebook.imgix.net/assets/landingpage/bridebook-home-v1.jpg?auto=format&w=800");`,
    backgroundSize: 'cover',
    backgroundPosition: '70%',

    minWidthSmMin: {
      gridRow: 'span 2',
      gridColumn: 2,
      display: 'flex',
      width: '100%',
      backgroundImage: `url("https://bridebook.imgix.net/assets/landingpage/bridebook-home-v1.jpg?auto=format&w=2500");`,
    },
  },

  heroContent: {
    width: '100%',
    height: '100%',
    minHeight: 250,
    padding: 5,
    justifyContent: 'flex-end',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%)',

    minWidthSmMin: {
      padding: 10,
    },

    minWidthMdMin: {
      padding: 20,
      background: 'rgba(255, 242, 225, .15)',
      justifyContent: 'center',
    },
  },

  headline: {
    fontPlayfair: 42,
    color: colors.white,
    lineHeight: 1.1,
    margin: 0,
    maxWidth: 700,

    minWidthSmMin: {
      fontPlayfair: 54,
      lineHeight: 1.3,
    },

    minWidthMdMin: {
      fontPlayfair: 64,
      lineHeight: 1.3,
    },
  },
});

export default styles;
