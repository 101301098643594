import { countryCodeToHubspotId } from 'cms-suppliers/lib/premium/utils/hubspot/get-hubspot-id';
import gazetteer, { CountryCodes } from '@bridebook/toolbox/src/gazetteer';

export const countriesWithMarketInsights = Object.values(CountryCodes).filter(
  (code) => ![CountryCodes.DE, CountryCodes.FR, CountryCodes.IE].includes(code),
);

export const countriesWithRW = [CountryCodes.GB, CountryCodes.DE, CountryCodes.FR, CountryCodes.IE];

export const countriesWithSupplierRecommendations = [CountryCodes.GB, CountryCodes.DE];

export const countriesWithTrendingCarousel = [
  CountryCodes.GB,
  CountryCodes.DE,
  CountryCodes.FR,
  CountryCodes.IE,
];
export const countriesWithRecommendedCarousel = [CountryCodes.GB, CountryCodes.DE];
export const countriesWithSpecialOffersCarousel = [CountryCodes.GB];
export const countriesWithWeddingFairsCarousel = [CountryCodes.GB];
export const countriesWithRealWeddingCarousel = [CountryCodes.GB];

export const countriesWithLateAvailabilityFilter = [CountryCodes.GB, CountryCodes.DE];

export const countriesWithROI = [CountryCodes.GB];
export const countriesWithMultiCountyForVenues = [
  CountryCodes.GB,
  CountryCodes.DE,
  CountryCodes.FR,
  CountryCodes.IE,
];

export const countriesWithMultiCountyForSuppliers = [CountryCodes.GB];

export const countriesWithBadges = [CountryCodes.GB, CountryCodes.DE, CountryCodes.IE];

export const countriesWithPowerMatch = [
  CountryCodes.GB,
  CountryCodes.DE,
  CountryCodes.FR,
  CountryCodes.IE,
];

export const countriesWithLostLeadModal = [
  CountryCodes.GB,
  CountryCodes.DE,
  CountryCodes.FR,
  CountryCodes.IE,
];

export const countriesWithSuggestionDropdown = [
  CountryCodes.GB,
  CountryCodes.IE,
  CountryCodes.DE,
  CountryCodes.FR,
  CountryCodes.CA,
  CountryCodes.AU,
];

export const countriesWithLostLeadsStats = [
  CountryCodes.GB,
  CountryCodes.DE,
  CountryCodes.FR,
  CountryCodes.IE,
];

export const countriesWithVideoToursBanner = [CountryCodes.GB];

export const countriesWithDifferentAdminArea = [CountryCodes.FR, CountryCodes.DE];

export const countriesWithAdvice = gazetteer
  .getCountries({ monetized: true })
  .map((country) => country.id);

export const countriesWithSupplierPro = [CountryCodes.GB, CountryCodes.DE];

export const marketsWithLandingPages = gazetteer
  .getMarkets({ blocked: false })
  .filter((market) => market.getLocaleFlags()?.public && market.hasAnyDirectory);

export const countriesWithHubspot = [CountryCodes.GB, ...Object.keys(countryCodeToHubspotId)];

/**
 * Countries explicitly excluded from the signup dropdown.
 */
export const countriesExcludedFromSignup = [CountryCodes.US];

/**
 * Force searching with curated suppliers list filter turned on by default
 */
export const countriesWithForcedCuratedListSearch = [CountryCodes.US];

/**
 * Countries with required phone number
 */
export const countriesWithRequiredPhoneNumber = [CountryCodes.GB, CountryCodes.FR];

// Countries with county field visible
export const countriesWithAdminArea2Input = [CountryCodes.GB, CountryCodes.IE];
// Countries with several admin areas 1
// E.g. GB has several admin areas 1: Wales, Scotland, Northern Ireland and England
export const countriesWithSeveralAdminArea1 = [CountryCodes.GB];

/**
 * Countries with reveal email being available for VP only
 */
export const countriesWithVPEmailReveal = [CountryCodes.GB, CountryCodes.IE];

export const countriesWithHiddenQuantityOfVenues = [
  CountryCodes.GB,
  CountryCodes.IE,
  CountryCodes.DE,
  CountryCodes.FR,
];
