import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  modal: (isVisible: boolean) => FelaCSS;
  option: FelaCSS;
}

const styles = (): IStyles => ({
  modal: (isVisible) => ({
    display: isVisible ? 'flex' : 'none',
    width: '100%',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 10000,
    backgroundColor: colors.white,
    animationName: {
      '0%': {
        opacity: 0,
        transform: 'translateY(-30px)',
      },
      '100%': {
        opacity: 1,
        transform: 'translateY(0)',
      },
    } as unknown as FelaCSS['animationName'],
    animationDuration: '0.15s',
    animationIterationCount: 1,
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease-in-out',
  }),

  option: {
    color: colors.space,
  },
});

export default styles;
