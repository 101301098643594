
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[market]/basic",
      function () {
        return require("private-next-pages/[market]/basic/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[market]/basic"])
      });
    }
  