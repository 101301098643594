import { CountrySelector } from 'app-basic/components/country-selector/country-selector';
import { getIsDesktop } from 'app-basic/lib/app/selectors';
import { useSelector } from 'app-basic/lib/utils';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import { Box, ICountryOption, LoadingCircle } from '@bridebook/ui';
import BridebookLogo from '@bridebook/ui/src/components/svg/bridebook-logo';
import { IconChevronRight } from '@bridebook/ui/src/icons';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import { getCareersUrl } from 'lib/url-helper';
import componentStyles from './global-home.style';

export const GlobalHomePage = () => {
  const { t } = useTranslation('landing');
  const market = useMarket();
  const [selected, setSelected] = useState<ICountryOption | undefined>();
  const isDesktop = useSelector(getIsDesktop);
  const styles = componentStyles();

  return (
    <Box style={styles.wrapper}>
      <Box style={styles.logoWrap}>
        <BridebookLogo color="indigoCrush" width={isDesktop ? 300 : 190} />
      </Box>

      <Box style={styles.contentWrap}>
        <Box style={styles.dropdown}>
          <Box mb={5}>{t('globalHome.countrySelector.header')}</Box>
          <CountrySelector onSelectCallback={setSelected} />
          {!!selected && (
            <Box mt={10} alignItems="center">
              <LoadingCircle size={40} color="indigoCrush" />
            </Box>
          )}
        </Box>

        <Box style={styles.contentBottom}>
          <Box as="h4" style={styles.contentHeader}>
            {t('globalHome.info.header')}
          </Box>
          <Box style={styles.contentText}>{t('globalHome.info.text')}</Box>

          <a href={getCareersUrl(market, 'global')} target="_blank" rel="noopener noreferrer">
            <Box style={styles.careersBtn}>
              <span>{t('globalHome.hiring')}</span>
              <IconChevronRight color="space60" />
            </Box>
          </a>
        </Box>
      </Box>

      <Box style={styles.hero}>
        <Box style={styles.heroContent}>
          <Box as="h1" style={styles.headline}>
            {t('globalHome.hero.headline', 'Your free wedding planning app')}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
