import { GlobalHomePage } from 'app-basic/components/global-home/global-home';
import Head from 'app-basic/components/head/head';
import { EdgeCacheTTL, basicGSSPWrapper } from 'app-basic/lib/get-basic-server-side-props-wrapper';
import { NextPage } from 'next';
import React from 'react';
import { AppType } from 'pages/_app';

const GlobalHome: NextPage = () => (
  <>
    <Head />
    <GlobalHomePage />
  </>
);

GlobalHome.AppType = AppType.Basic;

export const getServerSideProps = basicGSSPWrapper(['landing'], undefined, {
  vercelCacheControl: {
    maxAge: EdgeCacheTTL.WEEK,
    sMaxAge: EdgeCacheTTL.WEEK,
    staleWhileRevalidate: EdgeCacheTTL.WEEK,
  },
  cloudflareCacheControl: {
    maxAge: EdgeCacheTTL.DAY,
    sMaxAge: EdgeCacheTTL.DAY,
    staleWhileRevalidate: EdgeCacheTTL.DAY,
  },
});

export default GlobalHome;
