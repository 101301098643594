import React, {
  ChangeEventHandler,
  DetailedHTMLProps,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  MouseEventHandler,
  ReactElement,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useFela } from 'react-fela';
import { FelaCSS, FelaCSSWithCustomSelectors } from 'src/components/fela/flowtypes';
import { ValidationError } from '@bridebook//toolbox';
import { IconCrossBold } from '../../../icons';
import Box from '../../fela/Box';
import componentStyles from './input.style';

type InputNativeProps = Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'style' | 'ref'
>;

/**
 * Props for the Input component.
 *
 * @interface InputProps
 * @extends {InputNativeProps}
 */
export interface InputProps extends InputNativeProps {
  /**
   * A string used for testing purposes.
   */
  dataTest?: string;

  /**
   * Error message or validation error object. Passing `boolean` will prevent error
   * message from showing up, only affect input border color. Passing `string` or
   * `ValidationError` instance will show error message under the input.
   */
  error?: ValidationError | string | boolean | null;

  /**
   * Hides validation error message. This property is useful only when error property type is
   * typeof ValidationError. It allows to proceed error.prop with input.name comparison but
   * hides error message.
   */
  hideValidationErrorMsg?: boolean;

  /**
   * Helper text to provide additional information, shows up under input.
   */
  helper?: string;

  /**
   * Indicates whether the input is active or not (distinct border color).
   */
  isActive?: boolean;

  /**
   * Label text or React element for the input.
   */
  label?: string | ReactElement;

  /**
   * Prevents scrolling when the input is focused.
   */
  preventScroll?: boolean;

  /**
   * Indicates whether to show a clear button.
   */
  showClearBtn?: boolean;

  /**
   * Additional event handler for the clear button click.
   */
  onClear?: MouseEventHandler<ReactElement>;

  /**
   * React element to be placed before the input.
   */
  beforeInput?: ReactElement;

  /**
   * React element to be placed after the input.
   */
  afterInput?: ReactElement;

  /**
   * Style for the most external wrapper component using Fela CSS or Fela CSS with custom selectors.
   */
  containerStyle?: FelaCSS | FelaCSSWithCustomSelectors;

  /**
   * Style for the input wrapper component using Fela CSS or Fela CSS with custom selectors.
   */
  style?: FelaCSS | FelaCSSWithCustomSelectors;

  /**
   * Style for the native input component using Fela CSS or Fela CSS with custom selectors.
   */
  inputStyle?: FelaCSS | FelaCSSWithCustomSelectors;

  /**
   * Whether the input should be refocused after using the reset button.
   */
  refocusOnReset?: boolean;

  /**
   * Mark the input as readonly
   */
  readOnly?: boolean;

  /**
   * Additional event handler for the input click.
   */
  onClick?: MouseEventHandler<ReactElement>;

  /**
   * Input value type
   */
  type?: HTMLInputTypeAttribute;

  /**
   * Sets the max value for type 'number'
   */
  max?: number;
}

const InputComponent = forwardRef<HTMLInputElement, InputProps>((props: InputProps, ref) => {
  const {
    autoFocus,
    dataTest,
    disabled,
    error,
    helper,
    isActive,
    label,
    onChange,
    onClear,
    preventScroll,
    showClearBtn,
    refocusOnReset,
    required,
    beforeInput,
    afterInput,
    value,
    hideValidationErrorMsg,
    style = {},
    containerStyle = {},
    inputStyle = {},
    readOnly,
    onClick,
    type,
    max,
    ...inputProps
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState(value);
  const { css } = useFela();

  // Used in styling. Sets input border to error state.
  const hasError =
    error === true ||
    typeof error === 'string' ||
    (error instanceof ValidationError && error.prop === inputProps.name);

  const showErrorMsg =
    typeof error === 'string' ||
    (error instanceof ValidationError && error.prop === inputProps.name && !hideValidationErrorMsg);

  useEffect(() => {
    if (max != undefined && type === 'number' && value > max) {
      setInputValue(max);
    } else {
      setInputValue(value);
    }
  }, [max, type, value]);

  const styles = componentStyles({
    // Change input border color to red if showErroMsg or error property is set explicitly true.
    hasError,
    hasLabel: !!label,
    disabled,
    isActive,
    wrapperStyle: style,
    containerStyle,
    inputStyle,
    readOnly,
  });

  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus({ preventScroll });
    }
  }, [autoFocus, preventScroll]);

  const handleOnClick: MouseEventHandler<ReactElement> = useCallback((e) => {
    e.stopPropagation();
    inputRef.current?.focus();
    onClick?.(e);
  }, []);

  const onInputChangeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValue = e.target.value;
    onChange?.(e);
    setInputValue(newValue);
  };

  const onClearInputHandler: MouseEventHandler<ReactElement> = (e) => {
    onClear?.(e);
    setInputValue('');
    if (refocusOnReset) inputRef.current?.focus();
  };

  return (
    <Box style={styles.container}>
      <Box style={styles.wrapper} onClick={handleOnClick}>
        <Box style={styles.innerWrapper} setRef={wrapperRef}>
          {label && (
            <Box style={styles.label}>
              {label}
              {required && '*'}
            </Box>
          )}
          <Box style={styles.inputWrapper}>
            {beforeInput}
            <input
              data-test={dataTest}
              ref={ref || inputRef}
              className={css(styles.input)}
              onChange={onInputChangeHandler}
              value={inputValue}
              disabled={disabled}
              autoFocus={autoFocus}
              readOnly={readOnly}
              type={type}
              max={max}
              {...inputProps}
            />
          </Box>
        </Box>

        {showClearBtn && !!inputValue && (
          <Box style={styles.clearBtn} onClick={onClearInputHandler}>
            <IconCrossBold width={10} />
          </Box>
        )}
        {afterInput}
      </Box>
      {showErrorMsg && (
        <Box as={'p'} style={styles.error}>
          {typeof error === 'string' ? error : error.message}
        </Box>
      )}
      {helper && (
        <Box as={'p'} style={styles.helper}>
          {helper}
        </Box>
      )}
    </Box>
  );
});

export const Input = memo(InputComponent);
