import React, {
  ChangeEvent,
  KeyboardEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ValidationError } from '@bridebook/toolbox';
import { FelaCSS } from '../../../components/fela/flowtypes';
import mergeStyles from '../../../fela-utils/merge-styles';
import { IconChevron, IconSearch } from '../../../icons';
import { IDropdownOption } from '../../../types';
import Box from '../../fela/Box';
import componentStyles from './dropdown.style';

export interface IDropdownProps<Option extends IDropdownOption> {
  /**
   * Error message to be displayed below the input field. If true, the input field will be marked as
   * invalid, but no error message will be displayed. If a string is provided, it will be displayed
   * as the error message.
   *
   * If an instance of ValidationError is provided, the error message will be displayed as the
   * message property of the ValidationError instance.In order for this to work,
   * ValidationError.prop must equal the name of the input field, so keep in mind, that if an
   * instance of ValidationError is provided, the name property of the input field must be set,
   * otherwise it won't work.
   */
  error?: boolean | string | ValidationError | null;

  /**
   * Label for the input field. If not provided, the input field will not have a label.
   */
  label?: string;

  /**
   * Placeholder for the input field.
   */
  placeholder?: string;

  /**
   * List of options to be displayed in the dropdown. TODO: Require better typing.
   */
  options: Option[];

  /**
   * Callback function to be called when an option is clicked.
   */
  onSelect: (option: Option) => void;

  /**
   * The selected value of the dropdown. In order to select on of the options from countryOptions,
   * you must pass value that matches one of the options values.
   */
  selected?: string;

  /**
   * If value is a function, it will be called with selected option as an argument and the result
   * will be displayed as the value of the input field. If value is a string, it will be displayed
   * as the value of the input field. If not provided, the label of the selected option will be
   * displayed. Otherwise the placeholder will be displayed.
   */
  value?: ((option: Option) => JSX.Element) | string;

  /**
   * The function that will be called on external wrapper click.
   */
  onInputClick?(): void;

  /**
   * This property is responsible for some styles and behavior of the component when it is rendered
   * on mobile. If set to true, the click event event handler is not attached to the input field.
   */
  isMobile?: boolean;

  /**
   * Name of the input field. If provided, the input field will have the name attribute set to this
   * value.
   */
  name?: string;

  /**
   * If true, the input field will take the full width of the parent container.
   */
  fullWidth?: boolean;

  /**
   * If true, the input field will be disabled.
   */
  disabled?: boolean;

  /**
   * If true, the input field will be required.
   */
  required?: boolean;

  /**
   * Hide/show filtering input.
   */
  showFilter?: boolean;

  /**
   * Render option function. If provided, it will be called for each option in the list and the
   * result will be displayed as the option in the list.
   */
  renderOption?: (option: Option) => ReactNode;
  /**
   * If true, the dropdown will always be rendered in the DOM, even if hidden.
   */
  alwaysInDOM?: boolean;
  /**
   * Direction of the dropdown list relative to the input field.
   */
  direction?: 'top' | 'bottom';
  /**
   * Extra styles for wrapper element.
   */
  wrapperStyle?: FelaCSS;
  /**
   * Extra styles for inner wrapper element.
   */
  innerWrapperStyle?: FelaCSS;
}

export const Dropdown = <Option extends IDropdownOption>({
  error,
  label,
  placeholder,
  options,
  onSelect,
  onInputClick,
  selected,
  value,
  isMobile,
  name,
  fullWidth,
  disabled,
  showFilter,
  required,
  renderOption,
  alwaysInDOM,
  direction = 'bottom',
  wrapperStyle,
  innerWrapperStyle,
}: IDropdownProps<Option>) => {
  const [listOpen, setListOpen] = useState(false);
  const listElement = useRef();

  const filterInputRef = React.useRef<HTMLInputElement>(null);
  const selectedOption: Option = useMemo(
    () => options.find((option: Option) => option.value === selected),
    [options, selected],
  );

  const [displayOptions, setDisplayOptions] = useState(options);

  useEffect(() => {
    setDisplayOptions(options);
  }, [options]);

  useEffect(() => {
    if (!listOpen && displayOptions.length !== options.length) {
      setDisplayOptions(options);
    }
  }, [displayOptions, listOpen, options]);

  useEffect(() => {
    const closeListWhenClickedOutside = (event: MouseEvent) => {
      if (
        !isMobile &&
        event.target !== listElement.current &&
        event.target !== filterInputRef.current &&
        listOpen
      ) {
        setListOpen(false);
      }
    };
    window.addEventListener('click', closeListWhenClickedOutside);
    return () => {
      window.removeEventListener('click', closeListWhenClickedOutside);
    };
  }, [isMobile, listElement, listOpen]);

  const handleFilterChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      if (value === '') {
        setDisplayOptions(options);
        return;
      }
      setDisplayOptions(
        options.filter((option) => option.label.toLowerCase().includes(value.toLowerCase())),
      );
    },
    [options],
  );

  const onWrapperClick = useCallback(
    (event) => {
      // Ignore click if input is disabled
      if (disabled) return;
      listElement.current = event.target;

      // Call input click callback
      onInputClick?.();

      setListOpen((prev) => {
        // If filter is shown and list is closed, focus on input and open the list
        if (showFilter) {
          filterInputRef.current?.focus();
        }

        // Otherwise close list
        return !prev;
      });
    },
    [disabled, onInputClick, showFilter],
  );

  const handleOnItemClick = useCallback(
    (option: Option, event) => {
      event.stopPropagation();
      setDisplayOptions(options);
      onSelect(option);
      setListOpen(false);
    },
    [options, onSelect],
  );

  const handleKeyUp = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') setListOpen(false);
  }, []);

  const hasError =
    error === true ||
    typeof error === 'string' ||
    (error instanceof ValidationError && error.prop === name);

  const showErrorMsg =
    typeof error === 'string' || (error instanceof ValidationError && error.prop === name);

  const styles = componentStyles({
    hasError,
    listOpen,
    hasValue: !!selected,
    isMobile,
    fullWidth,
    disabled,
    optionsFound: displayOptions.length > 0,
    direction,
  });

  return (
    <Box>
      <Box
        style={mergeStyles([styles.wrapper, wrapperStyle || {}])}
        data-test="dropdown-wrapper"
        onClick={onWrapperClick}
        name={name}>
        <Box style={styles.container} data-test="dropdown-container">
          <Box
            style={mergeStyles([styles.innerWrapper, innerWrapperStyle || {}])}
            data-test="dropdown-inner-wrapper">
            <Box style={styles.filterInputContainer}>
              {!isMobile && listOpen && showFilter ? (
                <Box flexDirection="row" gap={4} style={styles.filterInputWrap}>
                  <IconSearch color="space60" width={16} />
                  <input
                    ref={filterInputRef}
                    onKeyUp={handleKeyUp}
                    autoFocus
                    onChange={handleFilterChange}
                    style={styles.filterInput}
                    placeholder={placeholder}
                  />
                </Box>
              ) : (
                <>
                  {label && (
                    <Box style={styles.label}>
                      {label}
                      {required && '*'}
                    </Box>
                  )}
                  <Box style={styles.placeholder}>
                    {
                      /** If value is function, call it with selected option as param, else display placeholder. If it is string - display string, otherwise display selected item or placeholder. */
                      typeof value === 'function'
                        ? selectedOption
                          ? value(selectedOption)
                          : placeholder
                        : value || selectedOption?.label || placeholder
                    }
                  </Box>
                </>
              )}
            </Box>
            <Box style={styles.chevron}>
              <IconChevron width={12} />
            </Box>
          </Box>
          <Box>
            {(listOpen || alwaysInDOM) && displayOptions.length > 0 && !isMobile && (
              <Box data-name="list-items" style={styles.list}>
                <Box style={styles.listInner}>
                  {displayOptions.map((option) => (
                    <Box
                      key={option.value}
                      style={styles.listItem(option.value === selected)}
                      value={option.value}
                      onClick={(event) => handleOnItemClick(option, event)}>
                      {renderOption ? renderOption(option) : option.label}
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {showErrorMsg && (
        <Box as="p" style={styles.error}>
          {typeof error === 'string' ? error : error.message}
        </Box>
      )}
    </Box>
  );
};
