import { getAlternateLinks, getCanonicalLink, getIsMainLiveCDN } from 'app-basic/lib/app/selectors';
import { useSelector } from 'app-basic/lib/utils';
import React, { FunctionComponent, memo } from 'react';
import HeadComponent from 'app-shared/components/head/head';

interface IProps {
  title?: string;
  description?: string | null;
  ogTitle?: string | null;
  ogDescription?: string | null;
  ogImage?: string | null;
  ogType?: string | null;
  ogUrl?: string | null;
  robots?: string | null;
  plainTitle?: boolean;
  plainOgTitle?: boolean;
  children?: React.ReactNode;
  canonicalUrlOverride?: string;
  isIndexable?: boolean;
  facetsParams?: string[];
  isArticle?: boolean;
  seoIndex?: boolean;
}

const PageHead: FunctionComponent<IProps> = (props) => {
  const isMainLiveCDN = useSelector(getIsMainLiveCDN);
  const canonical = useSelector(getCanonicalLink);
  const alternate = useSelector(getAlternateLinks);

  return (
    <HeadComponent
      {...props}
      isMainLiveCDN={isMainLiveCDN}
      alternate={alternate}
      canonical={canonical}>
      {props.children}
    </HeadComponent>
  );
};
export default memo(PageHead);
