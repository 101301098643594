import componentStyles from 'app-basic/components/country-selector/country-selector.style';
import { getAlternateLinks, getIsMobile } from 'app-basic/lib/app/selectors';
import { useSelector } from 'app-basic/lib/utils';
import { useTranslation } from 'next-i18next';
import Router from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';
import { Market } from '@bridebook/toolbox/src/gazetteer';
import { marketsWithLandingPages } from '@bridebook/toolbox/src/i18n/features';
import { Box, CountrySelector as CountrySelectorUI, ICountryOption } from '@bridebook/ui';
import { CountrySelectModalContent } from 'app-shared/components/contry-selector/country-select-modal-content';
import { getAlternativeMarketUrl } from 'lib/landing/get-alternative-market-url';
import { usePathWithoutMarket } from 'lib/utils/url';

interface IProps {
  direction?: Parameters<typeof CountrySelectorUI>[0]['direction'];
  initialSelection?: Market;
  onSelectCallback?(option: ICountryOption): void;
}

export const CountrySelector = ({ direction, initialSelection, onSelectCallback }: IProps) => {
  const { t } = useTranslation('common');
  const isMobile = useSelector(getIsMobile);
  const pathname = usePathWithoutMarket();
  const alternateLinks = useSelector(getAlternateLinks);
  const [isClient, setIsClient] = useState(false);

  // Map markets to dropdown options
  const options = useMemo(
    () =>
      marketsWithLandingPages
        .map((market) => ({
          value: getAlternativeMarketUrl(market, pathname, alternateLinks),
          label: market.getCountryName(),
          flagImg: market.getCountryFlagImageURL(),
          market,
        }))
        /**
         * Some countries have different names between server and browser.
         * A mismatch between the server-side and client-side rendered content leads to complex hydration issues.
         * It can completely break the countries list, displaying the wrong country names and links.
         * To avoid this, we sort countries by the country code, which is the same on both sides.
         * Then, on the client-side we re-render them again with a simple useEffect,
         * this time sorted by the country name for better user experience.
         */
        .sort((a, b) =>
          isClient
            ? a.market.getCountryName().localeCompare(b.market.getCountryName())
            : a.market.country.localeCompare(b.market.country),
        ),
    [alternateLinks, pathname, isClient],
  );

  useEffect(() => {
    setIsClient(true);
  }, []);

  const [selected, setSelected] = useState<ICountryOption | undefined>(
    initialSelection
      ? options.find((option) => option.market.country === initialSelection.country)
      : undefined,
  );

  const onSelect = (option: ICountryOption) => {
    Router.push(option.value, undefined, { locale: false });
    setSelected(option);
    onSelectCallback?.(option);
  };

  const styles = componentStyles();

  return (
    <CountrySelectorUI
      showFilter
      options={options}
      placeholder={t('countrySelector.placeholder')}
      onSelect={onSelect}
      isMobile={isMobile}
      fullWidth
      value={selected?.label}
      selected={selected?.value}
      renderOption={(option) => (
        // Required `suppressHydrationWarning` for label due to differences in country names between server and browser
        <a href={option.value} style={styles.option} suppressHydrationWarning>
          {option.label}
        </a>
      )}
      alwaysInDOM
      direction={direction}>
      {(onClose, onClick, showModal) =>
        isMobile ? (
          <Box data-test="country-selector-modal" style={styles.modal(showModal)}>
            <CountrySelectModalContent
              options={options}
              onClose={onClose}
              onClick={onClick}
              modalHeader={t('countrySelector.modal.header')}
              showFilter
              filterPlaceholder={t('countrySelector.modal.filter')}
              selected={selected?.value}
              renderOption={(option) => (
                // Required `suppressHydrationWarning` for label due to differences in country names between server and browser
                <Box as="a" href={option.value} style={styles.option} suppressHydrationWarning>
                  {option.label}
                </Box>
              )}
            />
          </Box>
        ) : null
      }
    </CountrySelectorUI>
  );
};
